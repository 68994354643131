<template>
  <b-modal
    id="doctor-warning-modal"
    title="คำเตือน: กรุณาตรวจสอบข้อมูลแพทย์"
    size="lg"
    ok-only
    ok-title="รับทราบ"
    ok-variant="warning"
    centered
    header-bg-variant="warning"
    header-text-variant="dark"
    @ok="handleOk"
  >
    <template #modal-title>
      <i class="fas fa-exclamation-triangle mr-2"></i>
      คำเตือน: ตรวจสอบข้อมูลทันตแพทย์
    </template>

    <div class="warning-content">
      <!-- Doctor Info -->
      <div class="doctor-info" v-if="doctorUser && doctorUser.fullName">
        <strong>
          {{ doctorUser.fullName }}
        </strong>
      </div>
      <!-- Additional Info -->
      <div
        class="mb-3 small text-muted"
        v-if="doctorUser && doctorUser.drLicenseNo"
      >
        <p class="mb-1">เลขที่ใบอนุญาต: {{ doctorUser.drLicenseNo }}</p>
      </div>

      <!-- Warning Messages -->
      <div class="warnings">
        <strong class="d-block mb-2">พบปัญหาดังต่อไปนี้:</strong>
        <ul class="list-unstyled">
          <li
            v-for="(invalid, index) in invalids"
            :key="index"
            class="text-danger mb-2"
          >
            <i class="fas fa-times-circle mr-2"></i>
            {{ warningMessages[invalid] }}
          </li>
        </ul>
      </div>

      <!-- New Information Section -->
      <div class="mt-4 border-top pt-3">
        <p class="mb-0">
          ทันตแพทย์ทุกท่าน รวมถึงทันตแพทย์ Part Time ที่ให้บริการในโครงการ
          "บัตรประชาชนใบเดียว" <br />
          จะต้องผ่านการอบรมจากทันตแพทยสภา และได้รับ e-Certificate <br />
          <br />
          โดยทันตแพทย์ Part Time สามารถเข้ารับการอบรมแบบย่อ (1 ชั่วโมง) <br />
          <strong>
            เพื่อให้การให้บริการเป็นไปตามมาตรฐาน และไม่เกิดปัญหาการเบิกจ่ายเงิน
          </strong>
        </p>
        <div class="text-center my-3">
          <b-img
            fluid
            src="https://dentcloud-public.s3.ap-southeast-1.amazonaws.com/S__36413446.jpg"
            alt="ภาพประกอบการอบรม"
            class="max-w-full"
          />
        </div>
      </div>

      <!-- Modified checkbox size and text -->
      <div class="mt-4 border-top pt-3">
        <b-form-checkbox
          v-model="dontShowToday"
          class="text-muted small"
          size="sm"
        >
          ข้ามการแจ้งเตือนนี้สำหรับวันนี้
        </b-form-checkbox>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "DoctorWarningModal",
  props: {
    doctorUser: {
      type: Object,
      default: () => ({}),
    },
    invalids: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      dontShowToday: false,
      warningMessages: {
        EXPIRED_LICENSE: "ใบอนุญาตหมดอายุ",
        FULLNAME_MISMATCH: "ชื่อ-นามสกุลไม่ตรงกับใบอนุญาต",
        COURSE_NOT_ATTENDED: "ยังไม่เข้าร่วมหลักสูตร",
        NOT_FOUND: "ไม่พบเลขใบอนุญาตในระบบ",
        INACTIVE_LICENSE: "ใบอนุญาตไม่มีผลบังคับใช้",
        INVALID_EXPIRATION_DATE: "วันหมดอายุของใบอนุญาตไม่ถูกต้อง",
        COURSE_NOT_PASSED: "ยังไม่ผ่านหลักสูตร",
        CONNECTION_TIMEOUT: "การเชื่อมต่อฐานข้อมุูลขัดข้อง",
      },
    };
  },
  methods: {
    showModal() {
      this.$bvModal.show("doctor-warning-modal");
    },
    hideModal() {
      this.$bvModal.hide("doctor-warning-modal");
    },
    handleOk() {
      if (this.dontShowToday && this.doctorUser?.uid) {
        const today = new Date().toISOString().split("T")[0];
        localStorage.setItem(
          "suppressedDoctorUid",
          JSON.stringify({
            uid: this.doctorUser.uid,
            date: today,
          })
        );
      }
    },
    isSuppressionValid(uid) {
      const stored = localStorage.getItem("suppressedDoctorUid");
      if (!stored) return false;

      const { uid: storedUid, date } = JSON.parse(stored);
      const today = new Date().toISOString().split("T")[0];

      return storedUid === uid && date === today;
    },
  },
};
</script>